@import 'src/App.module';

.headerBar {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 16px 0 16px;
}
