@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('../fonts/montserrat-v14-latin-500.woff2') format('woff2'),
    url('../fonts/montserrat-v14-latin-500.woff') format('woff');
}

.ant-layout {
  font-family: 'Montserrat', sans-serif !important;
}

.ant-layout-header {
  line-height: 14px !important;
}
