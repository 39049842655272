$shell-height: 60px;
$shell-color: #730228;
$primary-color: #e1ad13;
$secondary-color: #D2691E;
$third-color: #a62d47;
$gold-color: #FFD700; 

:export {
  shellHeight: $shell-height;
  shellColor: $shell-color;
  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  thirdColor: $third-color;
  goldColor: $gold-color;
}
//#6d233c