@import 'src/App.module';

.container {
  :global {
    [ant-click-animating-without-extra-node]:after {
      -webkit-animation: none !important;
      -moz-animation: none !important;
      -o-animation: none !important;
      -ms-animation: none !important;
      animation: none !important;
    }
  }

  margin-right: 1em;

  .connexion {
    background-color: $secondary-color !important;
    border: none !important;
    width: 100%;
  }

  .whiteText {
    color: #ffffff !important;
  }
  .blackText {
    color: $primary-color !important;
  }
}
