@import 'src/App.module';

.container {
  background-color: $shell-color;
  width: 100%;
  margin-top: 0;

  h5,a {
    color: #ffffff;
  }
  .linkLegalInfo {
    padding-bottom: 6px !important;
  }
  .bioText{
    width: 100%;
  }
}
