@import 'src/App.module';

.container {
  margin-right: auto;
  margin-left: auto;

  .registerText {
    margin-top: 3em;
    font-size: 13px;
  }
}

:global {
  .ant-form-item-required::before {
    display: none !important;
  }
}