@import "./styles/variable.module";

.App {
  text-align: center;
  min-height: 100vh;
  width: 100%;
  background: #fff !important;
  overflow: hidden;
}

.Header {
  height: $shell-height !important;
  width: 100%;
  background: $shell-color !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.Content {
  height: calc(100% - #{$shell-height});
  width: 100%;
  background: #FFFFFF !important;
}
.Footer {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: 70px !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  background: #fff !important;
}
