@import 'src/App.module';

.drawer {
  margin-top: $shell-height;


  .drawerBody {
    display: flex;
    height: calc(100% - #{$shell-height});
    flex-direction: column;
    justify-content: space-between;


    .drawerRoutes {

    }

    .drawerFooter {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: gray;
      font-weight: bold;
      font-style: italic;
      font-size: 0.75em;
      padding-bottom: 1em;

      & > :not(:last-child) {
        margin-bottom: 1em;
      }

      .version {
        color: $shell-color;

      }
    }
  }
}
